<template>
  <div class="dimbox register">
    <div class="mHeader">
      <div class="flexL tit">
        <p class="bold">결제 정보 {{ editMode ? "수정" : "등록" }}</p>
      </div>
      <button @click="close">
        <span class="material-icons"> close </span>
      </button>
    </div>
    <div class="mBody">
      <div class="flexL">
        <p class="bold">구분 <span>*</span></p>
        <el-select placeholder="선택" v-model="category">
          <el-option key="1계약금" label="계약금" value="1계약금"></el-option>
          <el-option key="2중도금" label="중도금" value="2중도금"></el-option>
          <el-option key="3추가금" label="추가금" value="3추가금"></el-option>
          <el-option key="4잔금" label="잔금" value="4잔금"></el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="category == '3추가금'">
        <p class="bold">추가금 정산 <span>*</span></p>
        <label>
          <input type="radio" :value="true" v-model="surchargeStatus" />
          당일결제
        </label>
        <label>
          <input type="radio" :value="false" v-model="surchargeStatus" />
          잔금
        </label>
      </div>
      <div class="flexL">
        <p class="bold">금액 <span>*</span></p>
        <input type="text" v-model="price" />
      </div>
      <div class="flexL" v-if="surchargeStatus == true">
        <p class="bold">
          결제일
          <span
            v-if="
              (category == '3추가금' && surchargeStatus == true) ||
              category != '3추가금'
            "
            >*</span
          >
        </p>
        <el-date-picker
          v-model="date"
          type="date"
          format="yyyy-MM-dd"
          placeholder="선택해 주세요."
          class="fullW"
        >
        </el-date-picker>
      </div>
      <div class="flexL" v-if="surchargeStatus == true">
        <p class="bold">결제 방법</p>
        <el-select placeholder="선택" v-model="method">
          <el-option key="카드" label="카드" value="카드"></el-option>
          <el-option key="현금" label="현금" value="현금"></el-option>
          <el-option key="입금" label="입금" value="입금"></el-option>
        </el-select>
      </div>
      <div class="flexL" v-if="method == '현금' || method == '입금'">
        <p class="bold">현금 영수증</p>
        <el-select placeholder="선택" v-model="receipt">
          <el-option key="발행" label="발행" value="발행"></el-option>
          <el-option key="미발행" label="미발행" value="미발행"></el-option>
        </el-select>
      </div>
      <div class="flexL">
        <p class="bold">메모</p>
        <textarea v-model="memo" />
      </div>
      <div class="buttonWrap">
        <button @click="close" class="close">취소</button>
        <button class="basic" @click="updateData">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["select", "balance", "addBalance"],
  data() {
    return {
      file: "",
      editMode: false,
      category: "",
      price: "",
      date: "",
      method: "",
      receipt: "",
      memo: "",

      surchargeStatus: true,
    };
  },
  watch: {
    price: function (newValue) {
      if (this.price != null) {
        const result = newValue
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.price = result;
      }
    },
  },
  mounted() {
    if (this.select != null) {
      this.editMode = true;
      this.category = this.select.category;
      this.price = this.select.price;
      this.date = this.select.date;
      this.method = this.select.method;
      this.receipt = this.select.receipt;
      this.memo = this.select.memo;
      this.surchargeStatus = this.select.surchargeStatus;
    }
  },
  methods: {
    uncomma(str) {
      str = String(str);
      return Number(str.replace(/[^\d]+/g, ""));
    },
    close() {
      this.$emit("close");
    },

    updateData() {
      if (this.category == "") {
        this.$alert("결제 구분을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.price == "") {
        this.$alert("금액을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
        // } else if (
        //   this.category == "3추가금" &&
        //   this.surchargeStatus == true &&
        //   this.uncomma(this.price) > this.addBalance
        // ) {
        //   this.$alert("입력한 금액이 잔금을 초과합니다.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
        //   return;
      } else if (
        this.category != "3추가금" &&
        this.surchargeStatus == true &&
        this.uncomma(this.price) > this.balance
      ) {
        this.$alert("입력한 금액이 잔금을 초과합니다.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (
        this.surchargeStatus == true &&
        (this.date == "" || this.date == null)
      ) {
        this.$alert("결제일을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        category: this.category,
        date: this.surchargeStatus == true ? this.date : "",
        price: this.uncomma(this.price),
        method: this.method,
        receipt: this.receipt,
        memo: this.memo,
        surchargeStatus: this.surchargeStatus,
      };
      this.$emit("updateData", data);
      this.$emit("close");
    },
  },
};
</script>
