<template>
  <div class="contents userInfo">
    <div class="title flexB">
      <h2>회원정보</h2>
    </div>
    <el-tabs class="el_tabs tab1" v-model="userInfo" @tab-click="handleTab()">
      <el-tab-pane label="이용내역" name="history">
        <div class="box one">
          <div class="inputBox">
            <div class="flexL" v-if="adminType == 'SUPER'">
              <p class="bold">권한</p>
              <label
                ><input
                  type="radio"
                  name="Role"
                  :value="true"
                  v-model="isAdmin"
                />관리자
              </label>
              <label
                ><input
                  type="radio"
                  name="Role"
                  :value="false"
                  v-model="isAdmin"
                />일반
              </label>
            </div>
            <div class="flexL" v-if="isAdmin && adminType == 'SUPER'">
              <p class="bold">메뉴 권한</p>
              <el-select
                v-model="userAdminType"
                placeholder="선택"
                class="searchCondition"
              >
                <el-option
                  key="SUPER"
                  label="슈퍼 관리자"
                  value="SUPER"
                ></el-option>
                <el-option
                  key="ADVISOR"
                  label="관리자+플래너"
                  value="ADVISOR"
                ></el-option>
                <el-option
                  key="MANAGER"
                  label="일반 관리자"
                  value="MANAGER"
                ></el-option>
                <el-option
                  key="PLANNER"
                  label="플래너"
                  value="PLANNER"
                ></el-option>
              </el-select>
            </div>

            <div class="flexL">
              <p class="bold">이메일(아이디)</p>
              <span>{{ email }}</span>
            </div>
            <div class="flexL">
              <p class="bold">이름</p>
              <input type="text" v-model="username" />
            </div>
            <div class="flexL">
              <p class="bold">연락처</p>
              <span>{{ mobileNo }}</span>
            </div>
            <div class="flexL">
              <p class="bold">신랑신부</p>
              <span>{{ bridegroom }}</span>
            </div>
            <div class="flexL">
              <p class="bold">관심 카테고리</p>
              <ul class="flex categories">
                <li v-for="(data, i) in categories" :key="i">#{{ data }}</li>
              </ul>
            </div>
            <div class="flexL" v-if="isAdmin == false">
              <p class="bold">배우자 이름</p>
              <input type="text" v-model="spouse" />
            </div>
            <div class="flexL" v-if="isAdmin == false">
              <p class="bold">배우자 연락처</p>
              <input type="text" v-model="spousePhone" />
            </div>
            <div class="flexL" v-if="isAdmin == false">
              <p class="bold">상태</p>
              <el-select
                placeholder="선택"
                v-model="status"
                class="searchCondition"
              >
                <el-option
                  key="TBD"
                  label="선택"
                  value="TBD"
                  disabled
                ></el-option>
                <el-option
                  key="부재중"
                  label="부재중"
                  value="부재중"
                ></el-option>
                <el-option key="잠재" label="잠재" value="잠재"></el-option>
                <el-option key="가망" label="가망" value="가망"></el-option>
                <!-- <el-option key="계약" label="계약" value="계약"></el-option> -->
                <el-option
                  key="스드메 계약"
                  label="스드메 계약"
                  value="스드메 계약"
                ></el-option>
                <el-option key="포기" label="포기" value="포기"></el-option>
                <el-option
                  key="예식 완료"
                  label="예식 완료"
                  value="예식 완료"
                ></el-option>
                <el-option
                  key="스드메+혼수 계약"
                  label="스드메+혼수 계약"
                  value="스드메+혼수 계약"
                ></el-option>
                <!-- <el-option
                  key="혼수+웨딩 계약"
                  label="혼수+웨딩 계약"
                  value="혼수+웨딩 계약"
                ></el-option> -->
                <el-option
                  key="상담 완료"
                  label="상담 완료"
                  value="상담 완료"
                ></el-option>
                <el-option key="이관" label="이관" value="이관"></el-option>
                <el-option
                  key="웨딩홀 계약"
                  label="웨딩홀 계약"
                  value="웨딩홀 계약"
                ></el-option>
                <el-option key="업체" label="업체" value="업체"></el-option>
                <el-option
                  key="타 회사 계약"
                  label="타 회사 계약"
                  value="타 회사 계약"
                ></el-option>
                <el-option
                  key="디렉터 계약"
                  label="디렉터 계약"
                  value="디렉터 계약"
                ></el-option>
                <el-option
                  key="혼수 계약"
                  label="혼수 계약"
                  value="혼수 계약"
                ></el-option>
              </el-select>
            </div>
            <div class="flexL">
              <p class="bold">마케팅수신동의</p>
              <label
                ><input
                  type="radio"
                  value="true"
                  v-model="marketingAgreement"
                  disabled
                />동의
              </label>
              <label
                ><input
                  type="radio"
                  value="false"
                  v-model="marketingAgreement"
                  disabled
                />미동의
              </label>
            </div>
            <div class="flexL">
              <p class="bold">예식날짜확정</p>
              <label
                ><input
                  type="radio"
                  :value="true"
                  v-model="weddingDateConfirm"
                />확정
              </label>
              <label
                ><input
                  type="radio"
                  :value="false"
                  v-model="weddingDateConfirm"
                />미정
              </label>
            </div>
            <div class="flexL" v-if="isAdmin == false">
              <p class="bold">결혼 예정일</p>
              <!-- <span>{{ moment(weddingDate).format("YYYY.MM.DD") }}</span> -->
              <el-date-picker
                v-model="weddingDate"
                type="date"
                placeholder="선택"
              >
              </el-date-picker>
            </div>
            <div class="flexL">
              <p class="bold">가입일</p>
              <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
            </div>
            <div class="flexL" v-if="isAdmin == false">
              <p class="bold">가입경로</p>
              <el-select
                placeholder="선택"
                v-model="signupPath"
                class="searchCondition"
              >
                <el-option key="카톡" label="카톡" value="카톡"></el-option>
                <el-option
                  key="이메일"
                  label="이메일"
                  value="이메일"
                ></el-option>
                <el-option key="애플" label="애플" value="애플"></el-option>
                <el-option
                  key="네이버"
                  label="네이버"
                  value="네이버"
                ></el-option>
                <!-- <el-option key="소개" label="소개" value="소개"></el-option>
                <el-option
                  key="박람회"
                  label="박람회"
                  value="박람회"
                ></el-option>
                <el-option key="워킹" label="워킹" value="워킹"></el-option>
                <el-option
                  key="상담 전화"
                  label="상담 전화"
                  value="상담 전화"
                ></el-option>
                <el-option
                  key="더 플래너 가입"
                  label="더 플래너 가입"
                  value="더 플래너 가입"
                ></el-option> -->
              </el-select>
            </div>
            <div class="flexL">
              <p class="bold">가입경로2</p>
              <el-select
                placeholder="선택"
                v-model="signupPath2"
                class="searchCondition"
              >
                <el-option
                  key="블로그"
                  label="블로그"
                  value="블로그"
                ></el-option>
                <el-option
                  key="인스타그램"
                  label="인스타그램"
                  value="인스타그램"
                ></el-option>
                <el-option key="소개" label="소개" value="소개"></el-option>
                <el-option key="워킹" label="워킹" value="워킹"></el-option>
                <el-option
                  key="제휴 DB"
                  label="제휴 DB"
                  value="제휴 DB"
                ></el-option>
              </el-select>
            </div>
            <div class="flexL" v-if="dropedAt">
              <p class="bold">탈퇴일</p>
              <span>{{ moment(dropedAt).format("YYYY.MM.DD HH:mm") }}</span>
            </div>
            <div class="flexL">
              <p class="bold">최종 수정일</p>
              <span>{{
                updatedAt ? moment(updatedAt).format("YYYY.MM.DD HH:mm") : "-"
              }}</span>
            </div>
          </div>
          <div class="buttonWrap">
            <button v-if="!dropedAt" class="basic" @click="submit">수정</button>
            <button v-else class="basic" @click="goback">목록</button>
          </div>
        </div>
        <div class="box one" v-if="editMode2 && isAdmin == false">
          <h2 class="bold">업종별 관리 메모</h2>
          <ul class="memoList inputBox">
            <li>
              <p>스튜디오</p>
              <input type="text" v-model="vendorStudio" />
            </li>
            <li>
              <p>드레스/예복</p>
              <input type="text" v-model="vendorDress" />
            </li>
            <li>
              <p>헤어/메이크업</p>
              <input type="text" v-model="vendorMakeup" />
            </li>
            <li>
              <p>부케</p>
              <input type="text" v-model="vendorBouquet" />
            </li>
            <li>
              <p>허니문</p>
              <input type="text" v-model="vendorHoneymoon" />
            </li>
            <li>
              <p>예물</p>
              <input type="text" v-model="vendorGift" />
            </li>
            <li>
              <p>청첩장/식전영상</p>
              <input type="text" v-model="vendorInvitation" />
            </li>
            <li>
              <p>본식 원판/스냅</p>
              <input type="text" v-model="vendorWeddingPhoto" />
            </li>
            <li>
              <p>본식 영상</p>
              <input type="text" v-model="vendorWeddingVideo" />
            </li>
            <li>
              <p>한복/예단/폐백</p>
              <input type="text" v-model="vendorHanbok" />
            </li>
            <li>
              <p>주례/사회자</p>
              <input type="text" v-model="vendorEvent" />
            </li>
            <li>
              <p>기타</p>
              <input type="text" v-model="vendorEtc" />
            </li>
          </ul>
          <div class="buttonWrap">
            <button class="basic" @click="handleVendorMemo">수정</button>
          </div>
        </div>
        <el-tabs v-model="activeName" class="tab2" @tab-click="handleTab">
          <el-tab-pane :label="`메모(${memoTotal})`" name="memo">
            <div class="box one memoInput">
              <div>
                <textarea
                  v-model="memo"
                  placeholder="메모 내용을 입력해 주세요."
                ></textarea>
                <button class="register" @click="makeMemo" :disabled="inactive">
                  등록하기
                </button>
              </div>
            </div>
            <div class="box one">
              <table v-if="memoList.length > 0" class="mt0">
                <colgroup>
                  <col style="width: 10%" />
                  <col style="width: 10%" />
                  <col style="width: 60%" />
                  <col style="width: 15%" />
                  <col style="width: 5%" />
                </colgroup>
                <tr>
                  <th scope="col">번호</th>
                  <th scope="col">작성자</th>
                  <th scope="col">내용</th>
                  <th scope="col">날짜</th>
                  <th scope="col"></th>
                </tr>
                <tr v-for="(data, i) in memoList" :key="i">
                  <td>
                    {{ currentPage > 1 ? i + currentPage * 10 + 1 : i + 1 }}
                  </td>
                  <td>{{ data.author.username }}</td>
                  <td class="prewrap">{{ data.content }}</td>
                  <td>
                    {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
                  </td>
                  <td style="text-align: center">
                    <button class="delete" @click="deleteMemo(data._id)">
                      삭제
                    </button>
                  </td>
                </tr>
              </table>
              <div class="pagination" v-if="memoList.length > 0">
                <el-pagination
                  layout="prev, pager, next"
                  :total="memoTotal"
                  :page-size="10"
                  @current-change="handleCurrentChange"
                ></el-pagination>
              </div>
              <NoData v-else />
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="`문의 내역(${qnaTotal})`"
            name="qna"
            v-if="isAdmin == false"
          >
            <div class="box one">
              <table v-if="qnaList.length > 0">
                <colgroup>
                  <col style="width: 10%" />
                  <col style="width: 15%" />
                  <col style="width: 50%" />
                  <col style="width: 15%" />
                  <col style="width: 10%" />
                </colgroup>
                <tr>
                  <th scope="col">번호</th>
                  <th scope="col">카테고리</th>
                  <th scope="col">제목</th>
                  <th scope="col">날짜</th>
                  <th scope="col">답변상태</th>
                </tr>
                <tr v-for="(data, i) in qnaList" :key="i">
                  <td>
                    {{ currentPage > 1 ? i + currentPage * 10 + 1 : i + 1 }}
                  </td>
                  <td>
                    {{
                      data.category == "RESERVATION"
                        ? "상품 이용 예약"
                        : data.category == "COMPOSITION"
                        ? "상품 구성 문의"
                        : data.category == "QUOTATION"
                        ? "견적 문의"
                        : data.category == "CONSULT"
                        ? "상담 요청"
                        : data.category == "ETC"
                        ? "기타"
                        : data.category
                    }}
                  </td>
                  <td>
                    <router-link
                      :to="{ name: 'qnareply', query: { id: data._id } }"
                      >{{ data.title }}</router-link
                    >
                  </td>
                  <td>
                    {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
                  </td>
                  <td
                    :class="{
                      waiting: data.isReply == false,
                      done: data.isReply == true,
                    }"
                  >
                    {{ data.isReply == true ? "답변완료" : "답변대기" }}
                  </td>
                </tr>
              </table>
              <div class="pagination" v-if="qnaList.length > 0">
                <el-pagination
                  layout="prev, pager, next"
                  :total="qnaTotal"
                  :page-size="10"
                  @current-change="handleCurrentChange"
                ></el-pagination>
              </div>
              <NoData v-else />
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="`상담 내역(${consultTotal})`"
            name="consult"
            v-if="isAdmin == false"
          >
            <div class="box one">
              <table v-if="consultList.length > 0">
                <colgroup>
                  <col style="width: 10%" />
                  <col style="width: 30%" />
                  <col style="width: 10%" />
                  <col style="width: 10%" />
                  <col style="width: 15%" />
                  <col style="width: 15%" />
                  <col style="width: 10%" />
                </colgroup>
                <tr>
                  <th scope="col">번호</th>
                  <th scope="col">상담 내용</th>
                  <th scope="col">플래너</th>
                  <th scope="col">결혼 예정일</th>
                  <th scope="col">예식 지역</th>
                  <th scope="col">날짜</th>
                  <th scope="col">답변상태</th>
                </tr>
                <tr v-for="(data, i) in consultList" :key="i">
                  <td>
                    {{ currentPage > 1 ? i + currentPage * 10 + 1 : i + 1 }}
                  </td>
                  <td>
                    <router-link
                      :to="{ name: 'consultreply', query: { id: data._id } }"
                      >{{ data.comment }}</router-link
                    >
                  </td>
                  <td>{{ data.planner?.plannerName }}</td>
                  <td>{{ moment(data.weddingDate).format("YYYY.MM.DD") }}</td>
                  <td>{{ data.region }}</td>
                  <td>
                    {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
                  </td>
                  <td
                    :class="{
                      waiting: data.isReply == false,
                      done: data.isReply == true,
                    }"
                  >
                    {{ data.isReply == true ? "답변완료" : "답변대기" }}
                  </td>
                </tr>
              </table>
              <div class="pagination" v-if="consultList.length > 0">
                <el-pagination
                  layout="prev, pager, next"
                  :total="consultTotal"
                  :page-size="10"
                  @current-change="handleCurrentChange"
                ></el-pagination>
              </div>
              <NoData v-else />
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="`찜 내역(${likesList.total})`"
            name="dib"
            v-if="isAdmin == false"
          >
            <div class="box one likesTable" v-if="likesList.total > 0">
              <table v-if="likesList.weddingPlaceLikes.length">
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 15%" />
                  <col style="width: 20%" />
                  <col style="width: 25%" />
                  <col style="width: 35%" />
                </colgroup>
                <tr></tr>
                <tr v-for="(data, i) in likesList.weddingPlaceLikes" :key="i">
                  <td>
                    <img :src="data.thumbnail" />
                  </td>
                  <td>웨딩 장소</td>
                  <td>
                    {{
                      data.category == "HALL"
                        ? "웨딩홀"
                        : data.category == "HOTEL"
                        ? "호텔"
                        : data.category == "HOUSE"
                        ? "스몰/야외&amp;하우스웨딩"
                        : data.category == "MEETING"
                        ? "상견례"
                        : data.category == "BIRTHDAY"
                        ? "돌잔치"
                        : data.category == "TRADITION"
                        ? "전통 혼례"
                        : ""
                    }}
                  </td>
                  <td>{{ data.title }}</td>
                  <td>{{ data.region }}</td>
                </tr>
              </table>
              <table v-if="likesList.plannerLikes.length">
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 15%" />
                  <col style="width: 20%" />
                  <col style="width: 25%" />
                  <col style="width: 35%" />
                </colgroup>
                <tr v-for="(data, i) in likesList.plannerLikes" :key="i">
                  <td>
                    <img :src="data.images[0]" />
                  </td>
                  <td>플래너</td>
                  <td>
                    {{ data.plannerName }}
                  </td>
                  <td>
                    {{
                      data.plannerType == "DIRECTOR"
                        ? "디렉터"
                        : data.plannerType == "PLANNER"
                        ? "플래너"
                        : data.plannerType == "TOURPLANNER"
                        ? "투어플래너"
                        : ""
                    }}
                  </td>
                  <td>{{ data.region.join() }}</td>
                </tr>
              </table>
              <table v-if="likesList.collectionLikes.length">
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 15%" />
                  <col style="width: 20%" />
                  <col style="width: 25%" />
                  <col style="width: 35%" />
                </colgroup>
                <tr v-for="(data, i) in likesList.collectionLikes" :key="i">
                  <td>
                    <img :src="data.thumbnail" />
                  </td>
                  <td>웨딩 컬렉션</td>
                  <td>
                    {{
                      data.category == "STUDIO"
                        ? "사진"
                        : data.category == "DRESS"
                        ? "드레스/예복"
                        : data.category == "MAKEUP"
                        ? "헤어&#38;메이크업/부케"
                        : data.category == "HONEYMOON"
                        ? "허니문"
                        : data.category == "VIDEO"
                        ? "웨딩영상"
                        : data.category == "EVENT"
                        ? "주례&#38;사회/이벤트"
                        : data.category == "GIFT"
                        ? "예물"
                        : data.category == "HANBOK"
                        ? "한복/예단"
                        : data.category == "PYEBAEK"
                        ? "폐백&#38;이바지/청첩장"
                        : data.category == "BEAUTY"
                        ? "뷰티케어"
                        : data.category == "INTERIOR"
                        ? "가전/가구/인테리어"
                        : data.category == "CATERING"
                        ? "케이터링"
                        : ""
                    }}
                  </td>
                  <td>{{ data.title }}</td>
                  <td>{{ data.agency }}</td>
                </tr>
              </table>
              <table v-if="likesList.storeLikes.length">
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 15%" />
                  <col style="width: 20%" />
                  <col style="width: 25%" />
                  <col style="width: 35%" />
                </colgroup>
                <tr v-for="(data, i) in likesList.storeLikes" :key="i">
                  <td>
                    <img :src="data.thumbnail" />
                  </td>
                  <td>스토어</td>
                  <td>
                    {{
                      data.category == "WEDDING"
                        ? "웨딩패키지"
                        : data.category == "BEAUTY"
                        ? "뷰티"
                        : data.category == "GIFT"
                        ? "답례품"
                        : data.category == "TRAVEL"
                        ? "여행"
                        : data.category == "ACTIVITY"
                        ? "액티비티"
                        : data.category == "CLASS"
                        ? "원데이클래스"
                        : data.category == "ETC"
                        ? "기타"
                        : ""
                    }}
                  </td>
                  <td>{{ data.seller }}</td>
                  <td>{{ data.title }}</td>
                </tr>
              </table>
            </div>
            <div class="box one" v-else>
              <NoData />
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>

      <el-tab-pane label="계약정보" name="contract" v-if="isAdmin == false">
        <div class="box one">
          <div class="inputBox">
            <div class="flexL">
              <p class="bold">신랑 이름</p>
              <input type="text" v-model="groom" />
            </div>
            <div class="flexL">
              <p class="bold">신랑 연락처</p>
              <input type="text" v-model="groomPhone" />
            </div>
            <div class="flexL">
              <p class="bold">신부 이름</p>

              <input type="text" v-model="bride" />
            </div>
            <div class="flexL">
              <p class="bold">신부 연락처</p>
              <input type="text" v-model="bridePhone" />
            </div>
            <div class="flexL">
              <p class="bold">예식 일시</p>
              <el-date-picker
                v-model="weddingDate"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                placeholder="날짜와 시간을 선택해 주세요."
              >
              </el-date-picker>
            </div>
            <div class="flexL">
              <p class="bold">예식 지역</p>
              <input type="text" v-model="weddingAddress" />
            </div>
            <div class="flexL">
              <p class="bold">예식 장소</p>
              <input type="text" v-model="weddingPlace" />
            </div>
            <div class="flexL">
              <p class="bold">촬영 장소</p>
              <input type="text" v-model="photoPlace" />
            </div>
            <div class="flexL">
              <p class="bold">촬영 일시</p>
              <el-date-picker
                v-model="photoDate"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                placeholder="날짜와 시간을 선택해 주세요."
              >
              </el-date-picker>
            </div>
            <div class="flexL">
              <p class="bold">담당 플래너</p>
              <el-select
                placeholder="선택"
                v-model="planner"
                class="searchCondition"
                @change="handlePlanner"
              >
                <el-option
                  v-for="(data, i) in plannerList.filter(
                    (item) =>
                      item.plannerType == 'PLANNER' && item.status != 'FINISHED'
                  )"
                  :key="i"
                  :value="data._id"
                  :label="data.plannerName"
                ></el-option>
              </el-select>
            </div>
            <div class="flexL">
              <p class="bold">담당 디렉터</p>
              <el-select
                placeholder="선택"
                v-model="director"
                class="searchCondition"
                @change="handlePlanner"
              >
                <el-option
                  v-for="(data, i) in plannerList.filter(
                    (item) =>
                      item.plannerType == 'DIRECTOR' &&
                      item.status != 'FINISHED'
                  )"
                  :key="i"
                  :value="data._id"
                  :label="data.plannerName"
                ></el-option>
              </el-select>
            </div>
            <div class="flexL">
              <p class="bold">패키지 계약 금액</p>
              <input type="text" v-model="payment" />
            </div>
            <!-- <div class="flexL" v-if="isAdmin == false">
              <p class="bold">상태</p>
              <el-select
                placeholder="선택"
                v-model="status"
                class="searchCondition"
              >
                <el-option
                  key="TBD"
                  label="선택"
                  value="TBD"
                  disabled
                ></el-option>
                <el-option
                  key="부재중"
                  label="부재중"
                  value="부재중"
                ></el-option>
                <el-option key="잠재" label="잠재" value="잠재"></el-option>
                <el-option key="가망" label="가망" value="가망"></el-option>
                <el-option
                  key="스드메 계약"
                  label="스드메 계약"
                  value="스드메 계약"
                ></el-option>
                <el-option key="포기" label="포기" value="포기"></el-option>
                <el-option
                  key="예식 완료"
                  label="예식 완료"
                  value="예식 완료"
                ></el-option>
                <el-option
                  key="스드메+혼수 계약"
                  label="스드메+혼수 계약"
                  value="스드메+혼수 계약"
                ></el-option>
                <el-option
                  key="상담 완료"
                  label="상담 완료"
                  value="상담 완료"
                ></el-option>
                <el-option key="이관" label="이관" value="이관"></el-option>
                <el-option
                  key="웨딩홀 계약"
                  label="웨딩홀 계약"
                  value="웨딩홀 계약"
                ></el-option>
                <el-option key="업체" label="업체" value="업체"></el-option>
                <el-option
                  key="타 회사 계약"
                  label="타 회사 계약"
                  value="타 회사 계약"
                ></el-option>
                <el-option
                  key="디렉터 계약"
                  label="디렉터 계약"
                  value="디렉터 계약"
                ></el-option>
                <el-option
                  key="혼수 계약"
                  label="혼수 계약"
                  value="혼수 계약"
                ></el-option>
              </el-select>
            </div> -->
            <div class="flexL">
              <p class="bold">계약서</p>
              <label for="fileUpload" class="file" v-if="files == ''">
                <button class="register">업로드</button>
                <input
                  type="file"
                  ref="fileUpload"
                  @change="sendFile()"
                  id="fileUpload"
                  accept="image/*,.pdf"
                />
              </label>
              <p v-else class="fileButton">
                <a :href="files"
                  >{{ decodeURI(filename) }}
                  <span class="material-icons-outlined">
                    file_download
                  </span></a
                >
                <button class="table" @click="handleDeleteFile">삭제</button>
              </p>
            </div>
          </div>
          <div class="buttonWrap">
            <button class="basic" @click="handleAgreement">
              {{ editMode2 == true ? "수정" : "등록" }}
            </button>
            <!-- <button @click="deleteAgreement">삭제</button> -->
          </div>
        </div>
        <div class="box one" v-if="editMode2">
          <div class="tableList">
            <h2 class="bold">
              상품 계약 및 체크리스트
              <button
                class="register"
                @click="handleContractModal('agreement', null, null)"
              >
                등록하기
              </button>
            </h2>
            <table v-if="agreements.length > 0">
              <colgroup>
                <col style="width: 10%" />
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: 20%" />
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: 10%" />
              </colgroup>
              <tr>
                <th>카테고리</th>
                <th>업체</th>
                <th>상품명</th>
                <th>날짜</th>
                <th class="right">
                  공급가
                  <!-- <br />
                  <el-radio label="included" v-model="vat">VAT 포함</el-radio>
                  <el-radio label="excluded" v-model="vat">VAT 제외</el-radio> -->
                </th>
                <th class="right">소비자 가격</th>
                <th class="center"></th>
              </tr>
              <tr v-for="(data, i) in agreements" :key="i">
                <td>{{ data.category }}</td>
                <td>{{ data.vendorName }}</td>
                <td @click="handleContractModal('agreement', data, i)">
                  <em>{{ data.product }}</em>
                </td>
                <td>
                  {{
                    data.date
                      ? moment(data.date).format("YYYY.MM.DD HH:mm")
                      : ""
                  }}
                </td>
                <td class="right">{{ numberFormat(data.supplyPrice) }}원</td>
                <td class="right">{{ numberFormat(data.price) }}원</td>
                <td class="center">
                  <button
                    class="table delete"
                    @click="itemDelete(i, 'agreement', data)"
                  >
                    삭제
                  </button>
                </td>
              </tr>
            </table>

            <div class="nodata" v-else>
              <p>데이터가 없습니다.</p>
            </div>
          </div>
        </div>
        <div class="box one" v-if="editMode2">
          <div class="tableList">
            <h2 class="bold">
              결제 정보

              <p class="flr">
                웨딩 패키지 계약금액 : {{ numberFormat(payment) }}원
                <button
                  class="register small"
                  @click="handleContractModal('payment', null, null)"
                >
                  등록하기
                </button>
              </p>
            </h2>

            <table v-if="payments.length > 0">
              <colgroup>
                <col style="width: 15%" />
                <col style="width: 15%" />
                <col style="width: 10%" />
                <col style="width: 10%" />
                <col style="width: 15%" />
                <col style="width: 25%" />
                <col style="width: 10%" />
              </colgroup>
              <tr>
                <th>구분</th>
                <th>결제일</th>
                <th>결제 방법</th>
                <th>현금영수증</th>
                <th class="center">금액</th>
                <th>메모</th>
                <th></th>
              </tr>
              <tr v-for="(data, i) in payments" :key="i">
                <td @click="handleContractModal('payment', data, i)">
                  <em
                    >{{ data.category.substring(1)
                    }}<span v-if="data.category == '3추가금'"
                      >({{
                        data.surchargeStatus == true ? "당일결제" : "잔금"
                      }})</span
                    ></em
                  >
                </td>

                <td>
                  {{ data.date ? moment(data.date).format("YYYY.MM.DD") : "" }}
                </td>
                <td>{{ data.method }}</td>
                <td>{{ data.receipt }}</td>
                <td class="right">{{ numberFormat(data.price) }}원</td>
                <td>{{ data.memo }}</td>
                <td class="center">
                  <button
                    class="table delete"
                    @click="itemDelete(i, 'payment')"
                  >
                    삭제
                  </button>
                </td>
              </tr>

              <tr>
                <td colspan="5">
                  <b>총 납부금액 : {{ numberFormat(totalPaid) }} 원 </b>
                  <b> (기존 잔금 : {{ numberFormat(balance) }} 원 </b>
                  <b> + 추가금 잔금 : {{ numberFormat(addBalance) }} 원 =</b>
                  <b> 총 잔금 : {{ numberFormat(balance + addBalance) }} 원)</b>
                </td>
              </tr>
            </table>

            <div class="nodata" v-else>
              <p>데이터가 없습니다.</p>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <AgreementModal
          v-if="type == 'agreement'"
          :select="select"
          :userId="userId"
          @close="close"
          @updateData="updateData"
        />
        <PaymentModal
          v-if="type == 'payment'"
          :select="select"
          :balance="balance"
          :addBalance="addBalance"
          @close="close"
          @updateData="updateData"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";
import {
  fetchUserDetails,
  upadateUserDetails,
  fetchQnAList,
  fetchConsultList,
  createUserMemo,
  fetchUserMemoList,
  removeMemo,
  createAgreement,
  updateAgreement,
  fetchAgreementDetail,
  fetchPlannerList,
  fetchUserLikesList,
  fetchVendorMemoDetail,
  updateVendorMemo,
  removeAgreement,
  removeSchedule,
} from "@/api/admin";
import { format } from "@/mixins/format";
import PaymentModal from "@/components/pop/PaymentModal";
import AgreementModal from "@/components/pop/AgreementModal";
import NoData from "@/components/contents/Nodata";

export default {
  mixins: [format],
  components: { AgreementModal, PaymentModal, NoData },
  name: "UserManage",

  data() {
    return {
      moment: moment,
      userId: "",
      isAdmin: "",
      username: "",
      email: "",
      mobileNo: "",
      createdAt: "",
      updatedAt: "",
      dropedAt: "",
      nickname: "",
      userInfo: "history",
      activeName: "memo",
      marketingAgreement: [],

      isNodata: false,
      postList: [],
      total: 0,
      consultTotal: 0,
      qnaTotal: 0,
      memoTotal: 0,
      currentPage: 1,
      modalVisible: false,
      memoVisible: false,
      paymentVisible: false,
      userAdminType: "",
      bridegroom: "",
      spouse: "",
      spousePhone: "",
      weddingDate: "",
      weddingDateConfirm: false,
      signupPath: "",
      status: "",
      categories: [],
      memo: "",
      memoList: [],
      adminType: "",
      inactive: false,

      plannerList: [],
      editMode: false,
      editMode2: false,
      groom: "",
      groomPhone: "",
      bride: "",
      bridePhone: "",
      weddingPlace: "",
      weddingAddress: "",
      photoPlace: "",
      photoDate: "",
      planner: "",
      director: "",
      payment: "",
      agreementId: "",
      files: "",
      file: "",

      type: "",
      select: null,
      agreements: [],
      totalPaid: 0,
      balance: 0,
      addBalance: 0,
      payments: [],

      vat: "included",
      filename: "",

      qnaList: [],
      consultList: [],
      likesList: "",

      vendorStudio: "",
      vendorDress: "",
      vendorMakeup: "",
      vendorBouquet: "",
      vendorHoneymoon: "",
      vendorGift: "",
      vendorInvitation: "",
      vendorWeddingPhoto: "",
      vendorWeddingVideo: "",
      vendorHanbok: "",
      vendorEvent: "",
      vendorEtc: "",
      vendorMemoId: "",
      signupPath2: "",
    };
  },
  watch: {
    payment: function (newValue) {
      if (this.payment != null) {
        const result = newValue
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.payment = result;
      }
    },
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "1");
  },
  beforeMount() {
    this.adminType = localStorage.getItem("adminType");
  },
  mounted() {
    this.userId = this.$route.query.id;
    this.userInfo = this.$route.query.tab ? this.$route.query.tab : "history";
    this.getUserDetail();
    this.getUserMemos();
    this.getPlannerList();
    this.getQnaList();
    this.getConsultList();
    this.getUserLikes();
    this.getVendorMemo();
  },

  methods: {
    goback() {
      this.$router.push("/admin/users");
    },
    handleTab() {
      this.postList = [];
      this.total = 0;
      this.currentPage = 1;
      if (this.activeName == "memo") {
        this.memoList = [];
        this.getUserMemos();
      }
    },
    close() {
      this.index = null;
      this.select = null;
      this.modalVisible = "";
      this.type = "";
      this.memoVisible = false;
      this.getAgreementDetail();
    },

    //  ========================================== 회원정보 관련 함수
    getUserDetail() {
      fetchUserDetails(this.userId).then((res) => {
        if (res.data.status == 200) {
          this.isAdmin = res.data.data.isAdmin;
          this.username = res.data.data.username;
          this.email = res.data.data.email;
          this.mobileNo = res.data.data.mobileNo;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
          this.marketingAgreement = res.data.data.marketingAgreement;
          this.weddingDateConfirm = res.data.data.weddingDateConfirm;
          this.dropedAt = res.data.data.dropedAt;
          this.userAdminType = res.data.data.adminType;
          this.bridegroom = res.data.data.bridegroom;
          this.weddingDate = res.data.data.weddingDate;
          this.categories = res.data.data.categories;
          this.spouse = res.data.data.spouse;
          this.spousePhone = res.data.data.spousePhone;
          this.status = res.data.data.status;
          this.signupPath = res.data.data.userType;
          this.signupPath2 = res.data.data.signupPath2;

          if (res.data.data.agreement) {
            this.getAgreementDetail();
          }
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getUserMemos() {
      let params = {
        userId: this.userId,
        page: this.currentPage,
      };
      fetchUserMemoList(params).then((res) => {
        if (res.data.status == 200) {
          this.memoList = res.data.data;
          this.memoTotal = res.data.total;
          this.currentPage = Number(res.data.Page);
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    submit() {
      let data = {
        userId: this.userId,
        username: this.username,
        isAdmin: this.isAdmin,
      };
      if (this.isAdmin == true) {
        data.adminType = this.userAdminType;
      } else {
        data.spouse = this.spouse;
        data.spousePhone = this.spousePhone;
        data.weddingDate = this.weddingDate;
        data.status = this.status;
        data.signupPath = this.signupPath;
        data.signupPath2 = this.signupPath2;
        data.adminType = "";
      }
      upadateUserDetails(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "회원 정보가 수정 되었습니다.",
            type: "success",
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    makeMemo() {
      if (this.memo == "") {
        this.$alert("내용을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      let data = {
        content: this.memo,
        user: this.userId,
      };
      this.inactive = true;
      createUserMemo(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "메모가 등록 되었습니다.",
            type: "success",
          });
          this.memo = "";
          this.inactive = false;
          this.getUserMemos();
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
          this.inactive = false;
          return;
        }
      });
    },
    deleteMemo(id) {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          removeMemo(id).then((res) => {
            if (res.data.status == 200) {
              this.$notify({
                title: "Success",
                message: "메모가 삭제 되었습니다.",
                type: "success",
              });
              this.getUserMemos();
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
              return;
            }
          });
        })
        .catch(() => {});
    },
    deleteAgreement() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          removeAgreement(this.agreementId).then((res) => {
            if (res.data.status == 200) {
              this.$notify({
                title: "Success",
                message: "계약정보가 삭제 되었습니다.",
                type: "success",
              });
              this.getAgreementDetail();
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
              return;
            }
          });
        })
        .catch(() => {});
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.activeName == "memo") {
        this.getUserMemos();
      }
    },
    getUserLikes() {
      let params = {
        userId: this.userId,
      };
      fetchUserLikesList(params).then((res) => {
        if (res.data.status == 200) {
          this.likesList = res.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getConsultList() {
      let params = {
        page: this.currentPage,
        userId: this.userId,
      };
      if (this.adminType == "PLANNER") {
        params.plannerId = localStorage.getItem("plannerId");
      }
      fetchConsultList(params).then((res) => {
        if (res.data.status == 200) {
          this.consultList = res.data.data;
          this.consultTotal = res.data.total;
          this.currentPage = Number(res.data.Page);
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getQnaList() {
      let params = {
        page: this.currentPage,
        userId: this.userId,
      };
      fetchQnAList(params).then((res) => {
        if (res.data.status == 200) {
          this.qnaList = res.data.data;
          this.qnaTotal = res.data.total;
          this.currentPage = Number(res.data.Page);
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    //  ========================================== 계약정보 관련 함수
    uncomma(str) {
      str = String(str);
      return Number(str.replace(/[^\d]+/g, ""));
    },
    handleAgreement() {
      let data = {
        user: this.userId,
        groom: this.groom,
        groomPhone: this.groomPhone,
        bride: this.bride,
        bridePhone: this.bridePhone,
        // status: this.status,
        weddingDate: this.weddingDate,
        photoPlace: this.photoPlace,
        photoDate: this.photoDate,
        weddingPlace: this.weddingPlace,
        weddingAddress: this.weddingAddress,
        files: this.files,

        agreements: this.agreements,
        payments: this.payments,
        payment: this.uncomma(this.payment),
      };
      if (this.planner != "") {
        data.planner = this.planner;
      }
      if (this.director != "") {
        data.director = this.director;
      }

      if (this.editMode2) {
        data.agreementId = this.agreementId;
        updateAgreement(data).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "계약 정보가 반영 되었습니다.",
              type: "success",
            });
            this.getAgreementDetail();
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createAgreement(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("계약 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.getAgreementDetail();
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            this.inactive = false;
          }
        });
      }
    },
    getAgreementDetail() {
      fetchAgreementDetail(this.userId).then((res) => {
        if (res.data.status == 200) {
          if (res.data.data != null) {
            this.totalPaid = 0;
            this.addBalance = 0;
            this.payment = 0;
            this.payments = "";

            this.editMode2 = true;
            this.groom = res.data.data.groom;
            this.groomPhone = res.data.data.groomPhone;
            this.bride = res.data.data.bride;
            this.bridePhone = res.data.data.bridePhone;
            this.planner = res.data.data.planner._id;
            this.director = res.data.data.director;
            this.weddingDate = res.data.data.weddingDate;
            this.photoPlace = res.data.data.photoPlace;
            this.photoDate = res.data.data.photoDate;
            this.weddingPlace = res.data.data.weddingPlace;
            this.weddingAddress = res.data.data.weddingAddress;
            this.files = res.data.data.files;

            let splitUrl = this.files.split("/"); //   "/" 로 전체 url 을 나눈다
            let split2 = splitUrl[splitUrl.length - 1].split("_");
            this.filename = split2[split2.length - 1]; // 나누어진 배열의 맨 끝이 파일명이다

            this.agreementId = res.data.data._id;
            this.agreements = res.data.data.agreements;
            this.payment = res.data.data.payment;
            this.payments = res.data.data.payments;

            // 입력한 결제 정보가 하나도 없을 때, 웨딩 패키지 금액 = 잔금 동일하게 맞춤
            if (this.payments.length == 0) {
              this.balance = this.payment;
            } else {
              // 총 납부금액 및 잔금 계산하기
              let addPrice = 0;
              this.payments.forEach((item) => {
                if (
                  item.surchargeStatus == true ||
                  (item.date && item.method)
                ) {
                  this.totalPaid += item.price;
                }
                if (item.category == "3추가금") {
                  if (item.surchargeStatus == false) {
                    this.addBalance += item.price;
                  } else {
                    addPrice += item.price;
                  }
                }
                this.balance = this.payment - this.totalPaid + addPrice;
              });
            }

            // 결제 카테고리마다 맨 앞에 숫자가 있음, 정렬을 위해서 임의로 붙인 번호
            // 1계약금, 2중도금, 3추가금, 4잔금
            // 숫자 순서대로 정렬함
            this.payments.sort((a, b) => {
              if (a.category > b.category) return 1;
              if (a.category < b.category) return -1;
              return 0;
            });
          }
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getPlannerList() {
      let params = {
        limit: 10000,
      };
      fetchPlannerList(params).then((res) => {
        if (res.data.status == 200) {
          this.plannerList = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    sendFile() {
      if (this.files) {
        this.$alert("삭제 후 업로드 해주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      if (this.file == undefined) {
        return;
      }
      this.file = this.$refs.fileUpload.files[0];
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.files = res.data.url;

            let splitUrl = this.files.split("/"); //   "/" 로 전체 url 을 나눈다
            let split2 = splitUrl[splitUrl.length - 1].split("_");
            this.filename = split2[split2.length - 1]; // 나누어진 배열의 맨 끝이 파일명이다

            this.file = "";
            document.getElementById("fileUpload").value = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("파일을 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        if (this.agreementId) {
          deletePostFile(this.agreementId, url).then((res) => {
            if (res.data.status == 200) {
              this.files = "";
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        } else {
          deleteFile(url).then((res) => {
            if (res.data.status == 200) {
              this.files = "";
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        }
      });
    },
    handleContractModal(type, data, index) {
      if (data != null && index != null) {
        this.select = data;
        this.index = index;
      }
      this.type = type;
      this.modalVisible = true;
    },
    updateData(data) {
      if (this.type == "agreement") {
        if (this.index != null) {
          this.agreements.splice(this.index, 1, data);
        } else {
          this.agreements.push(data);
        }
      } else if (this.type == "payment") {
        if (this.index != null) {
          this.payments.splice(this.index, 1, data);
        } else {
          this.payments.push(data);
          this.getAgreementDetail();
        }
      }
      this.handleAgreement();
    },
    itemDelete(index, type, data) {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          if (type == "agreement") {
            this.agreements.splice(index, 1);
            removeSchedule(data.diary);
          } else if (type == "payment") {
            this.balance = 0;
            this.addBalance = 0;
            this.payments.splice(index, 1);

            this.getAgreementDetail();
          }
          this.handleAgreement();
        })
        .catch(() => {});
    },
    getVendorMemo() {
      fetchVendorMemoDetail(this.userId).then((res) => {
        if (res.data.status == 200) {
          if (res.data.data != null) {
            this.vendorStudio = res.data.data.studio;
            this.vendorDress = res.data.data.dress;
            this.vendorMakeup = res.data.data.makeup;
            this.vendorBouquet = res.data.data.bouquet;
            this.vendorHoneymoon = res.data.data.honeymoon;
            this.vendorGift = res.data.data.gift;
            this.vendorInvitation = res.data.data.invitation;
            this.vendorWeddingPhoto = res.data.data.weddingPhoto;
            this.vendorWeddingVideo = res.data.data.weddingVideo;
            this.vendorHanbok = res.data.data.hanbok;
            this.vendorEvent = res.data.data.event;
            this.vendorEtc = res.data.data.etc;
            this.vendorMemoId = res.data.data._id;
          }
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleVendorMemo() {
      let data = {
        studio: this.vendorStudio,
        dress: this.vendorDress,
        makeup: this.vendorMakeup,
        bouquet: this.vendorBouquet,
        honeymoon: this.vendorHoneymoon,
        gift: this.vendorGift,
        invitation: this.vendorInvitation,
        weddingPhoto: this.vendorWeddingPhoto,
        weddingVideo: this.vendorWeddingVideo,
        hanbok: this.vendorHanbok,
        event: this.vendorEvent,
        etc: this.vendorEtc,
        memoId: this.vendorMemoId,
      };
      updateVendorMemo(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "업종별 메모 정보가 수정 되었습니다.",
            type: "success",
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },

    handlePlanner(newValue) {
      let temp = this.plannerList.find((item) => item._id == newValue);
      this.plannerPhone = temp.mobileNo;
    },
  },
};
</script>
