<template>
  <div class="dimbox register">
    <div class="mHeader">
      <div class="flexL tit">
        <p class="bold">상품 계약 정보 등록</p>
      </div>
      <button @click="close">
        <span class="material-icons"> close </span>
      </button>
    </div>
    <div class="mBody">
      <div class="flexL">
        <p class="bold">카테고리 <span>*</span></p>
        <el-select placeholder="선택" v-model="category">
          <el-option
            key="스튜디오"
            label="스튜디오"
            value="스튜디오"
          ></el-option>
          <el-option
            key="드레스/예복"
            label="드레스/예복"
            value="드레스/예복"
          ></el-option>
          <el-option
            key="헤어/메이크업"
            label="헤어/메이크업"
            value="헤어/메이크업"
          ></el-option>
          <el-option key="허니문" label="허니문" value="허니문"></el-option>
          <el-option key="부케" label="부케" value="부케"></el-option>
          <el-option key="예물" label="예물" value="예물"></el-option>
          <el-option
            key="청첩장/식전영상"
            label="청첩장/식전영상"
            value="청첩장/식전영상"
          ></el-option>
          <el-option
            key="본식 원판/스냅"
            label="본식 원판/스냅"
            value="본식 원판/스냅"
          ></el-option>
          <el-option
            key="본식 영상"
            label="본식 영상"
            value="본식 영상"
          ></el-option>
          <el-option
            key="한복/예단/폐백"
            label="한복/예단/폐백"
            value="한복/예단/폐백"
          ></el-option>
          <el-option
            key="주례/사회자"
            label="주례/사회자"
            value="주례/사회자"
          ></el-option>
          <el-option key="기타" label="기타" value="기타"></el-option>
        </el-select>
      </div>
      <div class="flexL">
        <p class="bold">
          업체
          <span>*</span>
        </p>
        <input type="text" v-model="vendorName" />
      </div>
      <div class="flexL">
        <p class="bold">
          상품
          <span>*</span>
        </p>
        <input type="text" v-model="product" />
      </div>
      <div class="flexL">
        <p class="bold">날짜</p>
        <el-date-picker
          v-model="date"
          type="datetime"
          placeholder="선택해 주세요."
          format="yyyy-MM-dd HH:mm"
          class="fullW"
        >
        </el-date-picker>
      </div>
      <div class="flexL">
        <p class="bold">내용</p>
        <textarea v-model="content" />
      </div>
      <div class="flexL">
        <p class="bold">공급가</p>
        <input type="text" v-model="supplyPrice" />
      </div>
      <div class="flexL">
        <p class="bold">판매가</p>
        <input type="text" v-model="price" />
      </div>
      <div class="flex checkFlex">
        <p class="bold">체크리스트</p>
        <label for="fileUpload" class="file" v-if="checkList == ''">
          <button class="register">업로드</button>
          <input
            type="file"
            ref="fileUpload"
            @change="sendFile()"
            id="fileUpload"
            accept="image/*,.pdf"
          />
        </label>
        <p v-else class="fileButton">
          <a :href="checkList"
            >{{ filename(checkList) }}
            <span class="material-icons-outlined"> file_download </span></a
          >
          <button class="table" @click="handleDeleteFile(checkList)">
            삭제
          </button>
        </p>
      </div>

      <div class="buttonWrap">
        <button @click="close" class="close">취소</button>
        <button class="basic" @click="updateData">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile, deleteFile } from "@/api/file";
import { createSchedule, updateSchedule } from "@/api/admin";
export default {
  props: ["select", "userId"],
  data() {
    return {
      editMode: false,
      category: "",
      vendorName: "",
      product: "",
      content: "",
      supplyPrice: "",
      price: "",
      checkList: "",
      file: "",
      date: "",
    };
  },
  watch: {
    price: function (newValue) {
      const result = newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.price = result;
    },
    supplyPrice: function (newValue) {
      const result = newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.supplyPrice = result;
    },
  },
  mounted() {
    if (this.select != null) {
      this.editMode = true;
      this.category = this.select.category;
      this.vendorName = this.select.vendorName;
      this.product = this.select.product;
      this.content = this.select.content;
      this.supplyPrice = this.select.supplyPrice;
      this.price = this.select.price;
      this.checkList = this.select.checkList;
      this.date = this.select.date;
      this.diaryId = this.select.diary;
    }
  },
  methods: {
    filename(url) {
      let splitUrl = url.split("/"); //   "/" 로 전체 url 을 나눈다
      let split2 = splitUrl[splitUrl.length - 1].split("_");
      let filename = split2[split2.length - 1]; // 나누어진 배열의 맨 끝이 파일명이다
      return decodeURI(filename);
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    close() {
      this.$emit("close");
    },
    sendFile() {
      if (this.checkList) {
        this.$alert("삭제 후 업로드 해주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      if (this.file == undefined) {
        return;
      }
      this.file = this.$refs.fileUpload.files[0];
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.checkList = res.data.url;
            this.file = "";
            document.getElementById("fileUpload").value = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("파일을 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            this.checkList = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },

    async updateData() {
      if (this.category == "") {
        this.$alert("카테고리를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.vendorName == "") {
        this.$alert("업체를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.product == "") {
        this.$alert("상품을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
        // } else if (this.supplyPrice == "") {
        //   this.$alert("공급가를 입력해 주세요.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
        //   return;
        // } else if (this.price == "") {
        //   this.$alert("판매가를 입력해 주세요.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
        //   return;
      }
      let data = {
        category: this.category,
        vendorName: this.vendorName,
        product: this.product,
        content: this.content,
        price: this.uncomma(this.price),
        supplyPrice: this.uncomma(this.supplyPrice),
        checkList: this.checkList,
        date: this.date,
      };

      let diary = {
        diaryId: this.diaryId,
        title: this.category,
        content: this.content,
        date: this.date,
        user: this.userId,
      };

      if (this.editMode) {
        data.diary = this.diaryId;
        await updateSchedule(diary);
      } else {
        await createSchedule(diary).then((res) => {
          if (res.data.status == 200) {
            data.diary = res.data.data;
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }

      this.$emit("updateData", data);
      this.$emit("close");
    },
  },
};
</script>
